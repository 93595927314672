.contact-form {
    width: 100vw;
    max-width: 400px;
    /* Adjust the maximum width as needed */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px #c600b6;
}

.contact-form h2, .contact-form p {
    text-align: center;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

.contact-form textarea {
    resize: vertical;
    /* Allow vertical resizing */
}

.contact-form button[type="submit"] {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(to right, #c600b6, #bb8bb7);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button[type="submit"]:hover {
    background-color: #0056b3;
}

@media (max-width: 1200px) {
    .contact-form {
        width: 80vw;
        overflow-x: hidden;
    }
    .ContactContainer {
        width: 100vw;
        overflow-x: hidden;
    }
}