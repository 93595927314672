.topTitle {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.descriptionContainer {
    width: 90vw;
    text-align: center;
    margin-left: 5vw;
}

.descriptionContainerChanging {
    width: 90vw;
    margin-left: 5vw;
    height: 15vh;
}

.tablesContainer {
    display: flex;
    width: 100vw;
    justify-content: space-around;
}

/* .tablesContainer div {
    width: 50%;
} */

table {
    width: 75vh;
    border-collapse: collapse;
}

th {
    background-color: #f2f2f2;
    padding: 8px;
    text-align: left;
}

td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

td,
th {
    border: 1px solid #ddd;
}

.dimensions {
    text-align: center;
}

.measurements {
    margin-left: 5vw;
}

@media (max-width: 1200px) {
    table {
        width: 100%;
        border-collapse: collapse;
    }
    .tablesContainer {
        display: flex;
        width: 90vw;
        flex-direction: column;
        margin-left: 5vw;
    }
}