.navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 92%;
    padding: 2% 4%;
    text-align: end;
    height: 5vh;
    align-items: center;
    display: grid;
    z-index: 99;
    justify-content: center;
    background-image: linear-gradient(to right, #c600b6, #bb8bb7);
}

.pageTitle {
    position: absolute;
    top: 0vh;
    left: 5vw;
    z-index: 100;
    color: white;
    font-family: cursive;
}

.pageTitleLink {
    text-decoration: none;
    color: white;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    display: inline-block;
    margin-right: 20px;
}

.navbar ul li:last-child {
    margin-right: 0;
}

.navbar ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.menuBars {
    color: white;
    font-size: 30px;
    position: absolute;
    right: 5vw;
    top: 20px;
}

.popover-menu-container {
    display: grid;
}

.popover-menu {
    position: absolute;
    top: 50px;
    right: 5vw;
    background-image: linear-gradient(to right, #c600b6, #bb8bb7);
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
    text-align: center;
}

.popover-menu a {
    display: block;
    padding: 8px 12px;
    color: black;
    background-color: whitesmoke;
    text-decoration: none;
    border: 1px solid #88007a;
    border-radius: 5px;
    margin: 5px;
}

.popover-menu a:hover {
    background-color: #f4f4f4;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: -15px;
    display: none;
    background-image: linear-gradient(to right, #c600b6, #bb8bb7);
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 4px;
    padding: 8px;
    width: 100px;
    text-align: center;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu ul li {
    padding: 8px 12px;
}

.dropdown-link {
    display: block;
    color: #fff;
    text-decoration: none;
    font-weight: normal !important;
    padding: 10px 0 10px 0;
}

.dropdown-link:hover {
    color: #333;
}

@media (max-width: 1200px) {
    .navbar {
        left: 0;
        width: 95vw;
    }
}