.aboutContainer {
	width: 100vw;
}

.aboutFirstContainer {
	display: flex;
}

.image-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 45vw;
}

.aboutImage {
	width: 45vw;
	height: auto;
	max-height: 400px;
	object-fit: contain;
	margin: 0;
}

.aboutText {
	width: 45vw;
	margin-left: 5vw;
}

.topBuffer {
	width: 100vw;
	height: 15vh;
}

.image-upload-container {
	width: 90%;
	margin: 15px 0;
	padding: 10px;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	background-color: #f9f9f9;
}

.image-upload-container label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
	color: #333;
}

.file-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #f8f8f8;
	margin-bottom: 5px;
}

.upload-status {
	margin-top: 5px;
	color: #c600b6;
	font-size: 0.9em;
	font-style: italic;
}

.file-size-note {
	margin-top: 5px;
	font-size: 0.8em;
	color: #666;
	font-style: italic;
}

.editbody {
	width: 100%;
	height: 30vh;
}

.spacer {
	width: 100vw;
	height: 3px;
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.bottomAbout {
	margin-left: 5vw;
	width: 90vw;
	text-align: center;
}

.saveChangesBtn {
	margin-left: 45%;
	color: white;
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	padding: 10px;
	border-radius: 5px;
	margin-top: 20px;
}

@media (max-width: 1200px) {
	.aboutFirstContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.image-section {
		width: 90vw;
	}

	.aboutImage {
		width: 90vw;
		margin: 0;
	}

	.aboutText {
		width: 90vw;
		margin-left: 0vw;
		margin-top: 10vh;
	}

	.bottomAbout {
		text-align: center;
		width: 90vw;
		margin-left: 5vw;
	}
}
