.playhousesContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	margin: auto;
}

.playhouseCard {
	box-shadow: 0 0 10px rgb(174, 174, 174);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin: 10px;
	padding: 20px;
	width: 40vw;
	border-radius: 10px;
}

.playhouseCard img {
	object-fit: cover;
	width: 40vh;
	height: 40vh;
	margin: 10px;
	box-shadow: 0 0 10px rgb(200, 200, 200);
	border-radius: 10px;
}

.playhouseCard p {
	margin-top: 10px;
	text-align: center;
}

.playhouse-image-upload {
	width: 100%;
	margin: 10px 0;
	padding: 10px;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	background-color: #f9f9f9;
}

.playhouse-image-upload label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
	color: #333;
}

.playhouse-image-upload .file-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #f8f8f8;
	margin-bottom: 5px;
}

.playhouse-image-upload .upload-status {
	margin-top: 5px;
	color: #c600b6;
	font-size: 0.9em;
	font-style: italic;
}

.playhouse-image-upload .file-size-note {
	margin-top: 5px;
	font-size: 0.8em;
	color: #666;
	font-style: italic;
}

.playhouseCard a {
	text-decoration: none;
	font-weight: bold;
	color: rgb(3, 3, 165);
}

.EditableParagraph {
	width: 100%;
	height: 15vh;
}

@media (max-width: 1200px) {
	.playhouseCard {
		box-shadow: 0 0 10px rgb(174, 174, 174);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin: 10px;
		padding: 20px;
		width: 90vw;
		border-radius: 10px;
	}
}
