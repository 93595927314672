.html {
	width: 100vw;
	overflow-x: hidden;
}

.saveChangesBtn {
	margin-left: 45%;
	color: white;
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	padding: 10px;
	border-radius: 5px;
}

.changeView {
	justify-content: center;
	width: 100%;
	height: 5vh;
	margin-top: 10px;
	position: relative;
	align-items: center;
	display: grid;
}

.changeView button {
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	color: white;
}
.saveChangesBtn2 {
	color: white;
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	padding: 10px;
	border-radius: 5px;
}

.topBuffer {
	width: 100vw;
	height: 15vh;
}

.MainContainer {
	display: flex;
	width: 100vw;
	justify-content: space-around;
	align-items: center;
}

.slideshow-container {
	width: 45vw;
}

.mainContainerText {
	width: 45vw;
}

.editbody {
	width: 100%;
	height: 30vh;
}

.slideshow-editor {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: #f9f9f9;
}

.slideshow-editor h3 {
	margin-top: 0;
	color: #333;
}

.image-edit-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: white;
}

.image-edit-container div {
	margin-right: 15px;
	margin-bottom: 10px;
	flex: 1 1 200px;
}

.image-edit-container label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.image-edit-container input {
	width: 100%;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.image-help-text {
	background-color: #f8f8f8;
	padding: 10px;
	border-radius: 5px;
	border-left: 3px solid #c600b6;
	margin-bottom: 15px;
	font-size: 0.9em;
}

.save-url-btn {
	margin-top: 5px;
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 3px;
	cursor: pointer;
}

.thumbnail {
	max-width: 100px;
	max-height: 100px;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 3px;
	margin-top: 5px;
}

.image-preview {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100px;
	background-color: #f5f5f5;
	border-radius: 4px;
	padding: 5px;
}

.no-image {
	color: #777;
	font-size: 0.9em;
	font-style: italic;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 60px;
}

.file-input {
	border: 1px solid #ddd;
	padding: 8px;
	border-radius: 4px;
	background-color: #f8f8f8;
}

.upload-status {
	margin-top: 5px;
	color: #c600b6;
	font-size: 0.9em;
	font-style: italic;
}

.file-size-note {
	margin-top: 5px;
	font-size: 0.8em;
	color: #666;
	font-style: italic;
}

.remove-image-btn {
	background-color: #ff6b6b;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 3px;
	cursor: pointer;
}

.add-image-btn {
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	color: white;
	border: none;
	padding: 8px 15px;
	border-radius: 3px;
	cursor: pointer;
	margin-top: 10px;
}

.mySlides {
	display: none;
}

.mySlides.active {
	display: block;
}

.footer {
	margin-top: 10vh;
	margin-bottom: 10vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.footer p {
	width: 30vw;
	text-align: center;
}

.footer p a {
	text-decoration: none;
	color: rgb(0, 0, 165);
}

.spacer {
	width: 100vw;
	height: 3px;
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	margin-top: 5vh;
	margin-bottom: 5vh;
}

@media (max-width: 1200px) {
	.MainContainer {
		display: flex;
		flex-direction: column;
		width: 100vw;
	}

	.slideshow-container {
		width: 100vw;
	}

	.mainContainerText {
		width: 90vw;
		/* margin-left: 5vw; */
		text-align: center;
	}

	.footer img {
		display: none;
	}

	.footer p {
		text-align: center;
		width: 90vw;
	}

	.topBuffer {
		width: 100vw;
		height: 10vh;
	}

	.image-edit-container {
		flex-direction: column;
	}

	.image-edit-container div {
		width: 100%;
		margin-right: 0;
	}
}
