/* FAQ container */
.faq-container {
	width: 100vw;
	margin: 0 auto;
	text-align: center;
	overflow-x: hidden;
}

/* FAQ heading */
.faq-heading {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 30px;
	color: #333;
}

/* FAQ item */
.faq-item {
	margin-bottom: 40px;
}

/* FAQ question */
.faq-question {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
	width: 90vw;
	margin: 5vw;
	cursor: pointer;
}

/* FAQ answer */
.faq-answer {
	font-size: 16px;
	line-height: 1.6;
	color: #666;
	width: 90vw;
	margin: 5vw;
}

/* Footer */
.faq-footer {
	margin-top: 50px;
	text-align: center;
	color: #666;
}

/* Edit Mode Styles */
.loading {
	font-size: 18px;
	color: #666;
	margin: 50px 0;
}

.changeView {
	margin: 20px 0;
}

.changeView button {
	padding: 8px 16px;
	background-color: #3498db;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.title-edit {
	margin: 20px 0;
	text-align: center;
}

.title-edit label {
	display: block;
	margin-bottom: 8px;
	font-weight: bold;
}

.edit-input {
	width: 70%;
	padding: 8px;
	margin-bottom: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.edit-textarea {
	width: 70%;
	min-height: 100px;
	padding: 8px;
	margin-bottom: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
	resize: vertical;
}

.faq-item-edit {
	background-color: #f5f5f5;
	border-radius: 8px;
	padding: 20px;
	margin: 20px auto;
	width: 80%;
	position: relative;
}

.question-edit,
.answer-edit {
	margin-bottom: 15px;
	text-align: left;
}

.question-edit label,
.answer-edit label {
	display: block;
	margin-bottom: 8px;
	font-weight: bold;
}

.remove-faq-button {
	padding: 8px 16px;
	background-color: #e74c3c;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 10px;
}

.add-faq-button {
	padding: 8px 16px;
	background-color: #2ecc71;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin: 20px 10px;
}

.save-button {
	padding: 8px 16px;
	background-color: #f39c12;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin: 20px 10px;
}

.button-container {
	display: flex;
	justify-content: center;
	margin: 30px 0;
}

@media (max-width: 768px) {
	.edit-input,
	.edit-textarea {
		width: 90%;
	}

	.faq-item-edit {
		width: 90%;
	}
}
