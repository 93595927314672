.project {
	margin-bottom: 40px;
	overflow: hidden;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100vw;
	text-align: center;
}

.project-gallery h1 {
	text-align: center;
}

.slideshowBtn {
	position: absolute;
	right: 0;
}

.image-container {
	position: relative;
	height: 300px;
	/* Set the height of the image container */
}

.image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.3s ease;
}

.image-container img:hover {
	transform: scale(1.05);
	/* Zoom effect on hover */
}

.image-controls {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.image-controls button {
	background-color: rgba(255, 255, 255, 0.5);
	border: none;
	color: #333;
	font-size: 24px;
	padding: 10px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.image-controls button:hover {
	background-color: rgba(255, 255, 255, 0.8);
}

.project-content {
	padding: 20px;
}

.project h3 {
	margin-top: 5vh;
	font-size: 24px;
	color: #333;
}

.project p {
	margin: 10px 0;
	color: #666;
}

@media (max-width: 768px) {
	.image-controls {
		font-size: 18px;
	}
	.project h3 {
		width: 90vw;
	}
	.slideshowBtn {
		width: 100vw;
	}
	.slideshowBtn button {
		width: 30vw;
		height: 4vh;
	}
}

.project-gallery {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.project {
	width: 45%;
	margin-bottom: 40px;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.image-container {
	position: relative;
	height: 300px;
	overflow: hidden;
	margin-bottom: 20px;
	border-radius: 8px;
}

.image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.slideshowBtn {
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: flex;
}

.slideshowBtn button {
	background-color: rgba(255, 255, 255, 0.7);
	border: none;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slideshowBtn button:hover {
	background-color: rgba(255, 255, 255, 0.9);
}

.spacer {
	width: 100%;
	height: 1px;
	background-color: #f0f0f0;
	margin: 20px 0;
}

/* Admin editing styles */
.changeView {
	text-align: center;
	margin-bottom: 20px;
}

.changeView button {
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	color: white;
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
}

.admin-controls {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

.add-project-btn {
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
}

.project-edit-container {
	margin-top: 15px;
	background-color: #f9f9f9;
	padding: 15px;
	border-radius: 8px;
	border: 1px solid #eee;
}

.project-image-controls {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
}

.image-counter {
	font-size: 14px;
	color: #666;
	margin-bottom: 10px;
}

.image-upload-container {
	margin-bottom: 15px;
}

.image-upload-container label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}

.file-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
}

.upload-status {
	margin-top: 5px;
	color: #c600b6;
	font-size: 0.9em;
	font-style: italic;
}

.file-size-note {
	margin-top: 5px;
	font-size: 0.8em;
	color: #666;
	font-style: italic;
}

.image-action-buttons {
	display: flex;
	gap: 10px;
	margin-top: 10px;
}

.add-image-btn {
	background-image: linear-gradient(to right, #c600b6, #bb8bb7);
	color: white;
	border: none;
	padding: 8px 12px;
	border-radius: 4px;
	cursor: pointer;
	flex: 1;
}

.remove-image-btn {
	background-color: #ff6b6b;
	color: white;
	border: none;
	padding: 8px 12px;
	border-radius: 4px;
	cursor: pointer;
	flex: 1;
}

.project-text-edit {
	margin-bottom: 15px;
}

.project-text-edit label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}

.title-input {
	width: 100%;
	padding: 8px;
	margin-bottom: 15px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.description-input {
	width: 100%;
	height: 100px;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	resize: vertical;
}

.project-actions {
	display: flex;
	gap: 10px;
}

.save-project-btn {
	background-image: linear-gradient(to right, #4caf50, #8bc34a);
	color: white;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
	flex: 2;
}

.delete-project-btn {
	background-color: #f44336;
	color: white;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
	flex: 1;
}

.loading {
	text-align: center;
	padding: 40px;
	font-size: 18px;
	color: #666;
}

@media (max-width: 768px) {
	.project {
		width: 100%;
	}

	.image-action-buttons {
		flex-direction: column;
	}

	.project-actions {
		flex-direction: column;
	}
}
