.orderingContainer {
	text-align: center;
}

.content-wrapper {
	max-width: 1100px;
	margin: 0 auto;
	padding: 20px;
}

.edit-wrapper {
	max-width: 1100px;
	margin: 0 auto;
	padding: 20px;
}

.orderingP {
	width: 70vw;
	margin-left: 15vw;
	line-height: 1.8;
	color: #333;
	font-size: 16px;
	margin-bottom: 20px;
	max-width: 900px;
	margin: 0 auto 30px;
	text-align: left;
}

h3 {
	font-size: 1.5rem;
	color: #444;
	margin-top: 30px;
	margin-bottom: 15px;
	position: relative;
	display: inline-block;
	font-weight: 600;
}

h3:after {
	content: "";
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #3498db;
	border-radius: 2px;
}

.spacer {
	height: 1px;
	background-color: #e0e0e0;
	margin: 35px auto;
	width: 80%;
}

.changeView {
	text-align: center;
	margin: 20px 0 40px;
}

.changeView button {
	padding: 10px 20px;
	background-color: #3498db;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.changeView button:hover {
	background-color: #2980b9;
	transform: translateY(-2px);
}

.loading {
	text-align: center;
	padding: 50px 0;
	font-size: 18px;
	color: #777;
}

/* Styles for the editing mode */
.edit-section {
	margin: 30px auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
	width: 80%;
	max-width: 900px;
}

.edit-section label {
	display: block;
	font-weight: 600;
	margin-bottom: 10px;
	color: #333;
	text-align: left;
}

.section-edit-container {
	margin: 30px auto;
	padding: 25px;
	background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
	width: 80%;
	max-width: 900px;
	border-left: 4px solid #3498db;
	position: relative;
}

.section-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 20px;
}

.section-header label {
	font-weight: bold;
	margin-right: 15px;
	color: #333;
	min-width: 120px;
}

.section-content {
	margin-top: 15px;
}

.section-content label {
	display: block;
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
}

.title-input {
	flex: 1;
	padding: 10px 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 16px;
	min-width: 250px;
	margin-right: 15px;
	margin-bottom: 10px;
	transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.title-input:focus {
	border-color: #3498db;
	box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
	outline: none;
}

.content-textarea {
	width: 100%;
	min-height: 150px;
	padding: 12px 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 16px;
	resize: vertical;
	line-height: 1.5;
	transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.content-textarea:focus {
	border-color: #3498db;
	box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
	outline: none;
}

.remove-section-btn {
	padding: 8px 16px;
	background-color: #e74c3c;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.remove-section-btn:hover {
	background-color: #c0392b;
	transform: translateY(-2px);
}

.add-section-container {
	text-align: center;
	margin: 40px 0;
}

.add-section-btn {
	padding: 10px 20px;
	background-color: #2ecc71;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.3s ease, transform 0.2s ease;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.add-section-btn:hover {
	background-color: #27ae60;
	transform: translateY(-2px);
}

.saveChangesBtn {
	display: block;
	margin: 40px auto;
	padding: 12px 30px;
	background-color: #f39c12;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.3s ease, transform 0.2s ease;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.saveChangesBtn:hover {
	background-color: #e67e22;
	transform: translateY(-2px);
}

/* Responsive design */
@media (max-width: 768px) {
	.orderingP {
		width: 90vw;
		margin-left: 5vw;
	}

	.section-edit-container,
	.edit-section {
		width: 90%;
		padding: 15px;
	}

	.section-header {
		flex-direction: column;
		align-items: flex-start;
	}

	.title-input {
		width: 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}

	.remove-section-btn {
		align-self: flex-end;
	}

	h1 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1.3rem;
	}
}
