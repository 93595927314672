/* reviews.css */
.reviewsBuffer{
    height: 10vh;
}
/* Container for reviews */
.reviewsContainer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Content area */
.reviewsContent {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Title of reviews */
.reviewTitle {
    font-size: 2rem;
    margin-bottom: 10px;
}

/* Subtitle */
.reviewTitleP {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
}

/* List of reviews */
.reviewsList {
    width: 100%;
    max-width: 1200px;
    /* Increased maximum width for larger screens */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Two reviews per line, minimum width of 300px */
    gap: 20px;
    /* Gap between reviews */
}

/* Individual review */
.review {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px #c600b6;
}

/* Name of reviewer */
.reviewName {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

/* Age of reviewer */
.reviewAge {
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #666;
}

/* Comment of reviewer */
.reviewComment {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
}

/* Footer */
.reviewFooter {
    margin-top: auto;
}

/* reviews.css */

/* Style for the button */
.addTestimonialButton {
    background-image: linear-gradient(to right, #c600b6, #bb8bb7);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.addTestimonialButton:hover {
    background-color: #0056b3;
}

/* Style for the form container */
.addReviewForm {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

/* Style for input fields */
.reviewInput,
.reviewTextarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Style for the submit button */
.submitReviewButton {
   background-image: linear-gradient(to right, #c600b6, #bb8bb7);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.submitReviewButton:hover {
    background-color: #0056b3;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 900px) {

    /* Adjustments for smaller screens */
    .reviewsList {
        grid-template-columns: repeat(1, 1fr);
        /* One review per line */
    }
}